// ** React Imports
import { useEffect } from "react";

// ** Next Imports
import { useNavigate, useLocation } from "react-router-dom";
import { useAuth } from "../../../hooks/useAuth";

// ** Hooks Import

const GuestPath = [
  "/",
  "/login",
  "/register",
  "/forgotPassword",
  "/otp-verify",
  "/nda",
  "/privacy-policy",
  "/terms-condition",
  "/contact-us",
  "/about-us",
  "/ping",
];

const AuthGuard = (props) => {
  const { children, fallback } = props;
  const auth = useAuth();

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const currentPath = location.pathname + location.search + location.hash;
    // if (auth.user === null && !window.localStorage.getItem('userData')) {

    // If the user is not logged in
    if (auth.user === null) {
      // If the current path is not in the list of guest paths
      if (!GuestPath.includes(location.pathname)) {
        // Redirect the user to the login page with the original path as a query parameter
        navigate(`/login?redirect_url=${currentPath}`);
      }
    } else {
      // If the user is logged in
      // If the user is logged in and tries to access one of these routes
      window.scrollTo(0, 0);
      if (
        location.pathname === "/login" ||
        location.pathname === "/register" ||
        location.pathname === "/"
      ) {
        // Redirect the user to the home page
        navigate("/dashboard");
      }
    }
  }, [auth.user, location.hash, location.pathname, location.search, navigate]);

  if (
    !GuestPath.includes(location.pathname) &&
    (auth.loading || auth.user === null)
  ) {
    return fallback;
  }
  return children;
};

export default AuthGuard;
