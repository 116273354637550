import { useState } from "react";
import { Box, Button } from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";
import LogoutDialog from "../../../components/dialog/logoutDialog";
import Translations from "../Translations";
function AfterNavItemContent() {
  const [open, setOpen] = useState(false);
  const handleLogoutDialog = () => {
    setOpen((prev) => !prev);
  };
  return (
    <>
      <Box paddingLeft={2} paddingRight={2}>
        <Button
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            width: "100%",
            borderRadius: "14px",
            color: "#5E6B6A",
            fontWeight: 600,
            paddingTop: "12px",
            paddingBottom: "12px",
            paddingLeft: "32px",
            paddingRight: "32px",
            gap: "15px",
          }}
          startIcon={<LogoutIcon sx={{ ml: "10px", color: "#000" }} />}
          onClick={handleLogoutDialog}
        >
          <Translations text={"Logout"} />
        </Button>
      </Box>
      <LogoutDialog open={open} toggle={handleLogoutDialog} />
    </>
  );
}
export default AfterNavItemContent;
