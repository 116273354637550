import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from "@mui/material";
import { useAuth } from "../../hooks/useAuth";
import { useState } from "react";
import { LoadingButton } from "@mui/lab";
import Translations from "../../layouts/components/Translations";

function LogoutDialog({ open, toggle }) {
  const { logout } = useAuth();

  const [loading, setLoading] = useState(false);

  const handleLogout = () => {
    setLoading(true);
    setTimeout(() => {
      logout();
      setLoading(false);
    }, 1000);
  };

  return (
    <>
      <Dialog open={open} maxWidth="xs" fullWidth>
        <DialogContent>
          <Typography><Translations text={"Are you sure you want to Logout ?"} /></Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={toggle}><Translations text={"Cancel"} /></Button>
          <LoadingButton loading={loading} onClick={handleLogout}>
            <Translations text={"Logout"} />
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
}
export default LogoutDialog;
