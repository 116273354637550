import Avatar from "@mui/material/Avatar";
import BellIcon from "../../../../assets/images/bellIcon.svg";
import { Badge, IconButton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../../hooks/useAuth";
const AvatarStyle = {
  borderRadius: "200px",
  border: "1px solid rgba(80, 80, 80, 0.20)",
  backgroundColor: "#ffffff",
};
function Notification() {
  const navigate = useNavigate();
  const { unReadNotificationCount } = useAuth();

  console.log(
    "Notificaton Compo unReadNotificationCount",
    unReadNotificationCount
  );

  const handleClick = () => {
    navigate("settings/notification");
  };

  return (
    <>
      <IconButton onClick={handleClick} sx={{ p: 0 }}>
        <Badge
          color="error"
          overlap="circular"
          variant="dot"
          invisible={unReadNotificationCount > 0 ? false : true}
        >
          <Avatar alt="Remy Sharp" sx={{ ...AvatarStyle }}>
            <img src={BellIcon} alt="BellIcon"></img>
          </Avatar>
        </Badge>
      </IconButton>
    </>
  );
}
export default Notification;
