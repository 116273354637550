// ** MUI Imports
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";

// ** Icons Imports
import MenuIcon from "@mui/icons-material/Menu";

// ** Components
import UserDropdown from "../../../@core/layouts/components/shared-components/UserDropdown";
import Notification from "../../../@core/layouts/components/shared-components/notification";
import SearchBar from "../../../@core/layouts/components/shared-components/searchBar";
import { Avatar, Badge, Button } from "@mui/material";
import { Link } from "react-router-dom";
// import NotificationDropdown from 'src/@core/layouts/components/shared-components/NotificationDropdown'
import Seller from "../../../assets/images/seller.png";
import AutoCompleteComponent from "../Autocomplete";
import LanguageDropdown from "../../../@core/layouts/components/shared-components/LanguageDropdown";
import Translations from "../Translations";

const AvatarStyle = {
  borderRadius: "200px",
  border: "1px solid rgba(80, 80, 80, 0.20)",
  backgroundColor: "#ffffff",
};

const AppBarContent = (props) => {
  // ** Props
  const { hidden, settings, saveSettings, toggleNavVisibility } = props;

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <Box
        className="actions-left"
        sx={{ mr: 2, display: "flex", alignItems: "center" }}
      >
        {hidden ? (
          <IconButton
            color="inherit"
            sx={{ ml: -2.75 }}
            onClick={toggleNavVisibility}
          >
            <MenuIcon />
          </IconButton>
        ) : null}
      </Box>
      <Box
        className="actions-right"
        sx={{ display: "flex", alignItems: "center", gap: "5px" }}
      >
        {/* <NotificationDropdown settings={settings} /> */}
        <Link to={"/settings/buyer"} style={{ textDecoration: "none" }}>
          <Button
            sx={{
              // minWidth: { xs: "100px", sm: "100px", md: "175px", lg: "175px", xl: "175px" },
              display: {
                xs: "none",
                sm: "none",
                md: "flex",
                lg: "flex",
                xl: "flex",
              },
              color: "#fff",
              backgroundColor: "#007167",
              // fontSize: "12px",
              "&:hover": {
                color: "#fff",
                backgroundColor: "#007167",
              },
              textTransform: "none",
            }}
          >
            <Translations text={"Become a Seller"} />
          </Button>

          <Badge
            color="secondary"
            badgeContent={0}
            sx={{
              display: {
                xs: "flex",
                sm: "flex",
                md: "none",
                lg: "none",
                xl: "none",
              },
            }}
          >
            <Link to={"/settings/buyer"} style={{ textDecoration: "none" }}>
              <Avatar alt="Remy Sharp" sx={{ ...AvatarStyle }}>
                <img
                  src={Seller}
                  alt="BellIcon"
                  style={{ height: "30px", width: "30px" }}
                ></img>
              </Avatar>
            </Link>
          </Badge>
        </Link>
        <AutoCompleteComponent hidden={hidden} settings={settings} />
        <Notification />
        <LanguageDropdown settings={settings} saveSettings={saveSettings} />
        <UserDropdown settings={settings} />
      </Box>
    </Box>
  );
};

export default AppBarContent;
