import React from "react";
import { styled } from "@mui/system";
import { Typography, Breadcrumbs, Box } from "@mui/material";
import { Link } from "react-router-dom";
import Translations from "../../../layouts/components/Translations";

const FooterContainer = styled("footer")({
  borderTop: "1px solid rgba(0, 0, 0, 0.08)",
  background: "#FFF",
  padding: "15px 0",
});

function handleClick(event) {
  event.preventDefault();
  console.info("You clicked a breadcrumb.");
}

function Footer() {
  const breadcrumbsStyle = {
    // marginRight: "20px",
  };

  const allRightsReserveStyle = {
    // marginLeft: '30px',
    // color: "var(--new- text - dark, #132B23)",
    // fontSize: "16px",
    // fontStyle: "normal",
    // fontWeight: 500,
    // lineHeight: "24px", /* 150% */
    // letterSpacing: "0.25px",
    // textTransform: "capitalize",
  };

  return (
    <FooterContainer>
      <Box
        sx={{
          display: {
            xs: "none",
            sm: "none",
            md: "flex",
            lg: "flex",
            xl: "flex",
          },
          justifyContent: "space-between",
          p: 1,
          alignItems: "center",
        }}
      >
        <Typography
          variant="h6"
          component="div"
          // ml={3}
          sx={{ ...allRightsReserveStyle }}
        >
          <Translations text={"© 2024 | All Rights Reserved"} />
        </Typography>
        <div role="presentation" onClick={handleClick}>
          <Breadcrumbs aria-label="breadcrumb" sx={{ ...breadcrumbsStyle }}>
            <Typography
              as={Link}
              to={"/about-us"}
              color="text.primary"
              sx={{ textDecoration: "none" }}
            >
              <Translations text={"About"} />
            </Typography>
            <Typography
              as={Link}
              to={"/terms-condition"}
              color="text.primary"
              sx={{ textDecoration: "none" }}
            >
              <Translations text={"Terms and condition"} />
            </Typography>
            <Typography
              as={Link}
              to={"/contact-us"}
              color="text.primary"
              sx={{ textDecoration: "none" }}
            >
              <Translations text={"Contact"} />
            </Typography>
          </Breadcrumbs>
        </div>
      </Box>
      <Box
        sx={{
          display: {
            xs: "block",
            sm: "block",
            md: "none",
            lg: "none",
            xl: "none",
          },
          p: 1,
        }}
      >
        <Typography variant="h6" align="left" sx={{ ...allRightsReserveStyle }}>
          <Translations text={"© 2023 | All Rights Reserved"} />
        </Typography>
        <div role="presentation" onClick={handleClick}>
          <Breadcrumbs aria-label="breadcrumb" sx={{ ...breadcrumbsStyle }}>
            <Typography
              as={Link}
              to={"/about-us"}
              color="text.primary"
              sx={{ textDecoration: "none" }}
            >
              <Translations text={"About"} />
            </Typography>

            <Typography
              as={Link}
              to={"/terms-condition"}
              color="text.primary"
              sx={{ textDecoration: "none" }}
            >
              <Translations text={"Terms and condition"} />
            </Typography>
            <Typography
              as={Link}
              to={"/contact-us"}
              color="text.primary"
              sx={{ textDecoration: "none" }}
            >
              <Translations text={"Contact"} />
            </Typography>
          </Breadcrumbs>
        </div>
      </Box>
    </FooterContainer>
  );
}

export default Footer;
