import { createTheme, experimental_extendTheme } from "@mui/material/styles";

const theme = createTheme({
  typography: {
    fontFamily: "Ubuntu, sans-serif",
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          minWidth: 175,
          fontSize: 16,
          fontWeight: 600,
          letterSpacing: 0.16,
          borderRadius: 12,
          textTransform: "capitalize",
        },
        contained: {},
        containedDark: experimental_extendTheme(({ palette }) => ({
          // Extend 'contained' variant styles and override background color
          backgroundColor: "#000000",
          color: palette.getContrastText("#0e0e0e"), // Set text color for better contrast
        })),
      },
    },
  },
  palette: {
    common: {
      color: "white",
    },
    yellow: {
      main: "#FFC107",
      contrastText: "#fff"
    },
    green: {
      main: "#28A745",
      contrastText: "#fff"
    },
    orange: {
      main: "#DC3545",
      contrastText: "#fff"
    },
    blue: {
      main: "#007BFF",
      contrastText: "#fff"
    },
    lightPink: {
      main: "#FF9896",
      contrastText: "#fff"
    },
    primary: {
      main: "#007167",
      light: "#303A4B",
    },

    text: {
      // primary: "#007167",
      // primary: "#000000",
      // secondary: "#5E6B6A",
      disabled: "#959595",
    },
    secondary: {
      default: "#5E6B6A",
      main: "#1e1e1e",
    },
    danger: {
      main: "#E93C3C",
    },

    background: {
      default: "#FFF",
    },
  },
});

export default theme;
